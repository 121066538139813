import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    completedAt: String,
    stopsAt: String,
  };

  static targets = ["stopsAt"];

  connect() {
    this.element.addEventListener(
      "submit",
      this.validateBeforeSubmit.bind(this)
    );
  }

  validateBeforeSubmit(event) {
    if (this.hasCompletedAtValue && this.hasStopsAtTarget) {
      // Find the hidden input with the actual value
      const hiddenInput = this.stopsAtTarget.querySelector(
        'input[type="hidden"]'
      );

      if (hiddenInput && hiddenInput.value) {
        const stopsAt = new Date(hiddenInput.value);
        const originalStopsAt = new Date(this.stopsAtValue);
        const completedAt = new Date(this.completedAtValue);

        if (stopsAt.toISOString() !== originalStopsAt.toISOString() && stopsAt > completedAt) {
          // Prevent the default form submission
          event.preventDefault();

          // Show confirmation dialog
          if (
            confirm(
              "De einddatum is aangepast en hiermee zal het project worden herstart. Wilt u doorgaan?"
            )
          ) {
            // If user confirms, manually submit the request
            this.element.submit();
          }
        }
      }
    }
  }
}
