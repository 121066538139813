import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option"]

  connect() {
    this.expanded = false;
    this.summaryElement = this.element.previousElementSibling;
    this.licenseTypeField = document.querySelector('[name*="[license_type]"]:disabled');
    this.licenseTypeHiddenField = document.querySelector('input[type="hidden"][name*="[license_type]"]');

    this.summaryElement.addEventListener("click", this.toggleExpanded.bind(this));
    this.element.addEventListener("mouseup", this.handleSelect.bind(this));
    this.element.addEventListener("mousedown", this.handleMouseDown.bind(this));
    document.addEventListener("click", this.handleClickOutside.bind(this));

    this.updateLicenseType();
  }

  disconnect() {
    this.summaryElement.removeEventListener("click", this.toggleExpanded.bind(this));
    this.element.removeEventListener("mouseup", this.handleSelect.bind(this));
    this.element.removeEventListener("mousedown", this.handleMouseDown.bind(this));
    document.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  handleMouseDown(e) {
    if (e.target.tagName === "OPTION") {
      e.preventDefault();
    }
  }

  handleSelect(e) {
    if (e.target.tagName === "OPTION") {
      const option = e.target;
      const wasSelected = option.selected;

      requestAnimationFrame(() => {
        option.selected = !wasSelected;
        this.updateSummary();
        this.updateLicenseType();

        const event = new Event("change", { bubbles: true });
        this.element.dispatchEvent(event);
      });
    }
  }

  handleClickOutside(e) {
    if (!this.element.contains(e.target) && !this.summaryElement.contains(e.target)) {
      this.collapse();
    }
  }

  toggleExpanded(e) {
    e?.preventDefault();
    this.expanded = !this.expanded;
    this.element.classList.toggle('expanded', this.expanded);
    this.element.closest('.wp-multiple-select').classList.toggle('expanded', this.expanded);
  }

  collapse() {
    if (this.expanded) {
      this.expanded = false;
      this.element.classList.remove('expanded');
      this.element.closest('.wp-multiple-select').classList.remove('expanded');
    }
  }

  updateSummary() {
    const selectedOptions = Array.from(this.element.selectedOptions);
    const text = selectedOptions.length > 0
      ? selectedOptions.map(opt => opt.text).join(", ")
      : this.element.dataset.noneSelectedText;

    this.summaryElement.textContent = text;
  }

  updateLicenseType() {
    if (!this.licenseTypeField) return;

    const selectedValues = Array.from(this.element.selectedOptions).map(opt => opt.value);
    const newLicenseType = this.determineLicenseType(selectedValues);

    if (newLicenseType) {
      this.licenseTypeField.value = newLicenseType;
      this.licenseTypeHiddenField.value = newLicenseType;
      this.licenseTypeField.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }

  determineLicenseType(selectedRoles) {
    if (selectedRoles.length <= 1) {
      return 'basic';
    }

    if (selectedRoles.length === 2 && selectedRoles.includes('equipment_manager')) {
      return 'basic';
    }

    return 'premium';
  }
}
